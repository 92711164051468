import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import BlockText from "../block-text";
import Moment from "react-moment";
// import * as styles from "./project-preview.module.css";
// import { responsiveTitle3 } from "./typography.module.css";
import './blog-preview.css';
function BlogPreview(props) {
  return (
      
    
       <div className="blog-preview">  
     
     <h4 className="heading">{props.title}</h4>
      <div className="publish-date"><Moment  date={props.publishedAt} format="MMMM Do YYYY" /></div>
      {props.mainImage && props.mainImage.asset && (
          <Link className="preview-image" to={`/blogs/${props.slug.current}`}>
          <img 
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(900)
              .height(Math.floor((9 / 16) * 900))
              .url()}
            alt={props.mainImage.alt}
          /></Link>
        )}
       
        <div className="preview-text">{props._rawExcerpt && (
        <div >
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )}</div>
      <div className="continue-reading">
        <Link className="" to={`/blogs/${props.slug.current}`} replace={true}>Continue Reading</Link>
        <div className="underline"></div>
        </div>
        </div>
      
      
      
    
  );
}

export default BlogPreview;
