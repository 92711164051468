import { Link } from "gatsby";
import React from "react";
import BlogPreview from "./blog-preview";

// import * as styles from "./project-preview-grid.module.css";
import './blog-preview.css';
function BlogsList(props) {
  return (
    <div className="blog-preview-list">
        
        {props.nodes && props.nodes.map((node)=> {
        //   console.log(item.frontmatter.headerImage);
        //   console.log(item.frontmatter.headerImage!=null?item.frontmatter.headerImage["publicURL"]:"");
          return <BlogPreview {...node}/>
})}

      </div>
  );
}

BlogsList.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogsList;
