import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/blog-page.css'
import { StaticImage } from "gatsby-plugin-image";
import BlogsList from "../components/blogs/blogsList"
// import Footer from '../components/Footer/footer';
import Navbar from '../components/Navbar/Navbar'
export const query = graphql`
  query BrandOfMonthPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    blogs: allSanityBrand(
        
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            brandName
            mainImage {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
              alt
            }
            title
            _rawExcerpt
            slug {
              current
            }
          }
        }
      }
  }
`;

const BrandOfTheMonth = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const blogNodes = (data || {}).blogs
    ? mapEdgesToNodes(data.blogs)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        <div className="top-banner">
      
      <StaticImage className="banner" src='./images/5.png' alt="blogs"/>
      <Navbar/>
      </div>
           
      <div className="page-body-bloglist" >
      <BlogsList nodes={blogNodes} className="blog-list" type="blogs"/>
      </div>

  
    </Layout>
  );
};

export default BrandOfTheMonth;
